import axios from 'axios'
import Swal from 'sweetalert2'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE

// Language set to en always.
axios.interceptors.request.use((config) => {
  config.params = { ...config.params, lang: 'en' }
  return config
})

// Error message handling and redirect on token invalidation.
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 && response.data.messages?.[3]) {
      Swal.fire({
        title: 'Success',
        text: response.data.messages[3].join('<br/>'),
        icon: 'success',
      })
    }
    return response
  },
  (error) => {
    if (error.response) {
      const status = error.response.status
      const data = error.response.data

      if (status === 400) {
        Swal.fire({
          title: 'Error',
          text: data.messages.map((t: any) => Object.values(t).join('\n')).join('\n'),
          icon: 'error',
        })
      }

      if (status === 401) {
        Swal.fire({
          title: 'Session Expired',
          text: 'Your session has expired. Please log in again.',
          icon: 'warning',
        }).then(() => {
          localStorage.removeItem('@auth/token')
          window.location.href = '/login'
        })
      }
    }

    return Promise.reject(error)
  }
)
