/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { User } from '../../../../../app/modules/auth/core/_models'
import { meApi } from '../../../../../app/modules/auth/core/_requests'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const [user, setUser] = useState()
  const [token, setToken] = useState<string | undefined>(() => {
    return localStorage.getItem('@auth/token') ?? undefined
  })

  useEffect(() => {
    const fetchUser = async () => {
      const user = await meApi(token) // meApi'yi çağırarak kullanıcı bilgilerini alıyoruz

      if (!user) {
        setToken(undefined)
        return
      }
      setUser(user.userIdentifier)
    }
    fetchUser()
  }, [])
  let hasValidRole = true

  if (!hasValidRole) {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />
      </>
    )
  }
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/users/list'
        title='Users'
        fontIcon='bi-user'
        icon='/media/icons/duotune/general/gen067.svg'
      />
      <SidebarMenuItem
        to='/branches/list'
        title='Branches'
        fontIcon='bi-building'
        icon='/media/icons/duotune/general/gen025.svg'
      />
      <SidebarMenuItem
        to='/clubs/list'
        title='Clubs'
        fontIcon='bi-building'
        icon='/media/icons/duotune/general/gen056.svg'
      />
      <SidebarMenuItem
        to='/lanes/list'
        title='Lanes'
        fontIcon='bi-building'
        icon='/media/icons/duotune/general/gen059.svg'
      />
      <SidebarMenuItem
        to='/places/list'
        title='Places'
        fontIcon='bi-building'
        icon='/media/icons/duotune/general/gen015.svg'
      />
    </>
  )
}

export { SidebarMenuMain }
