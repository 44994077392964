// SchedulerModal.tsx
import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap'

interface SchedulerModalProps {
  showModal: boolean
  handleClose: () => void
  title: string
  startHour: number | undefined
  endHour: number | undefined
  resource: string | undefined
  setTitle: (title: string) => void
  setStartHour: (hour: number | undefined) => void
  setEndHour: (hour: number | undefined) => void
  setResource: (resource: string | undefined) => void
  handleSave: () => void
  handleDelete: () => void
  hours: string[]
  resources: string[]
}

const SchedulerEventEditModal: React.FC<SchedulerModalProps> = ({
  showModal,
  handleClose,
  title,
  startHour,
  endHour,
  resource,
  setTitle,
  setStartHour,
  setEndHour,
  setResource,
  handleSave,
  handleDelete,
  hours,
  resources,
}) => (
  <Modal show={showModal} onHide={handleClose} className='scheduler-modal-custom'>
    <Modal.Header closeButton>
      <Modal.Title>Add Event</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group controlId='formEventTitle'>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter event title'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId='formEventStart'>
          <Form.Label>Start Time</Form.Label>
          <Form.Control
            as='select'
            value={startHour ?? ''}
            onChange={(e) => setStartHour(parseInt(e.target.value, 10))}
          >
            <option value=''>Select Time</option>
            {hours.map((hour, index) => (
              <option key={index} value={index}>
                {hour}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId='formEventEnd'>
          <Form.Label>End Time</Form.Label>
          <Form.Control
            as='select'
            value={endHour ?? ''}
            onChange={(e) => setEndHour(parseInt(e.target.value, 10))}
          >
            <option value=''>Select Time</option>
            {hours.map((hour, index) => (
              <option key={index} value={index}>
                {hour}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId='formEventResource'>
          <Form.Label>Resource</Form.Label>
          <Form.Control
            as='select'
            value={resource ?? ''}
            onChange={(e) => setResource(e.target.value)}
          >
            <option value=''>Select Resource</option>
            {resources.map((resource, index) => (
              <option key={index} value={resource}>
                {resource}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button className='btn btn-warning' onClick={handleClose}>
        Cancel
      </Button>
      <Button className='btn btn-danger' onClick={handleDelete}>
        Delete
      </Button>
      <Button className='btn btn-success' onClick={handleSave}>
        Save
      </Button>
    </Modal.Footer>
  </Modal>
)

export default SchedulerEventEditModal
