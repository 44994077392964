import React from 'react'

import Scheduler from './Scheduler'

function DashboardPage() {
  return (
    <div>
      <Scheduler />
    </div>
  )
}

export default DashboardPage
