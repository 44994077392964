import React, { useEffect, useState } from 'react'
import BranchCard from './components/BranchCard'
import axios from 'axios'
import BranchEditModal from './BranchEditModal'

function BranchesList() {
  const [branches, setBranches] = useState<any[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<string>('')
  const [refresh, setRefresh] = useState<boolean>(false)
  const [limit, setLimit] = useState<number>(9)
  const [page, setPage] = useState<number>(1)
  const token = localStorage.getItem('@auth/token')

  const fetchBranches = () => {
    axios
      .get(`/admin/branches?page=${page}&pageSize=${limit}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBranches((prevBranches) => [...prevBranches, ...res.data['hydra:member']])
        setTotalCount(res.data['hydra:totalItems'])
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }

  const handleShowModal = (id: string) => {
    setItemIdForUpdate(id)
    setShowModal(true)
  }

  const handleHideModal = () => setShowModal(false)

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1)
  }

  useEffect(() => {
    fetchBranches()
    setRefresh(false)
  }, [token, refresh, page])

  return (
    <>
      <div className={`card`} style={{ background: 'none' }}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Branches</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              Total branch count is {totalCount}.
            </span>
          </h3>
          <div className='card-toolbar'>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a branch'
            >
              <button className='btn btn-sm btn-primary' onClick={() => handleShowModal('add')}>
                <i className='fas fa-map-marker-alt'></i>
                Add Branch
              </button>
            </div>
          </div>
        </div>
        {/* end::Header */}
        <div className='row g-5 g-xl-8 mt-2'>
          {branches && branches.length > 0 ? (
            branches.map((branch: any, index: number) => (
              <div className='col-xl-4' key={index}>
                <BranchCard
                  className='card-xl-stretch mb-xl-8'
                  image='abstract-4.svg'
                  title={branch.title}
                  id={branch.id}
                  refresh={setRefresh}
                />
              </div>
            ))
          ) : (
            <p>No branches found</p>
          )}
        </div>
        {branches.length < totalCount && (
          <div className='text-center mt-4'>
            <button className='btn btn-primary' onClick={handleLoadMore}>
              Load More
            </button>
          </div>
        )}
      </div>
      <BranchEditModal
        show={showModal}
        onClose={handleHideModal}
        id={itemIdForUpdate}
        refresh={setRefresh}
      />
    </>
  )
}

export default BranchesList
