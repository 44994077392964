import React, { Component } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import axios from 'axios'
import { Pagination } from 'react-bootstrap'

interface Props {
  title: string
  dataUrl: string
  editCallback: Function
  deleteCallback: Function
  actionItems?: Array<any>
  header?: Array<{
    index: number
    type: string
    subKey?: string
    title: string
    className: string
    key: string
  }>
  filter?: Array<any>
  itemsPerPage?: number
}

interface State<T> {
  data: T[]
  loading: boolean
  error: string | null
  page: number
  totalCount: number
}

class ResultViewer<T> extends Component<Props, State<T>> {
  static defaultProps = {
    itemsPerPage: 10,
  }
  token: string | null
  constructor(props: Props) {
    super(props)
    this.state = {
      data: [],
      loading: true,
      error: null,
      page: 1,
      totalCount: 0,
    }
    this.token = localStorage.getItem('@auth/token')
  }
  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps: Props, prevState: State<T>) {
    if (prevState.page !== this.state.page || prevProps.dataUrl !== this.props.dataUrl) {
      this.fetchData()
    }
  }

  fetchData = async () => {
    const { page } = this.state
    const { dataUrl } = this.props
    this.setState({ loading: true })
    axios
      .get(dataUrl + '?page=' + page + '&pageSize=' + this.props.itemsPerPage, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then((res) => {
        this.setState({
          data: res.data['hydra:member'],
          totalCount: res.data['hydra:totalItems'],
          loading: false,
        })
        console.log('State:', res.data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        this.setState({ error: 'Error fetching data', loading: false })
      })
  }

  handlePageChange = (newPage: number) => {
    this.setState({ page: newPage })
  }

  renderPagination = () => {
    const { page, totalCount } = this.state
    const totalPages = Math.ceil(totalCount / this.props.itemsPerPage!)
    const paginationItems = []

    for (let number = 1; number <= totalPages; number++) {
      paginationItems.push(
        <Pagination.Item
          key={number}
          active={number === page}
          onClick={() => this.handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      )
    }

    return (
      <Pagination>
        <Pagination.Prev
          onClick={() => page > 1 && this.handlePageChange(page - 1)}
          disabled={page === 1}
        />
        {paginationItems}
        <Pagination.Next
          onClick={() => page < totalPages && this.handlePageChange(page + 1)}
          disabled={page === totalPages}
        />
      </Pagination>
    )
  }

  render() {
    const { title, editCallback, deleteCallback, actionItems, header } = this.props
    const { data, loading, error, totalCount } = this.state

    return (
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{totalCount} results found.</span>
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
            >
              {actionItems?.map((item, index) => (
                <button
                  key={index}
                  className={item.class}
                  onClick={() => editCallback('add', this.fetchData)}
                >
                  <i className={item.icon} style={{ marginRight: '5px' }}></i>
                  {item.text}
                </button>
              ))}
            </div>
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : data && data.length > 0 ? (
            // begin::Table container
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  {header && header.length > 0 ? (
                    <tr className='fw-bold text-muted bg-light'>
                      {header.map((item, index) => (
                        <th key={index} className={item.className}>
                          {item.title}
                        </th>
                      ))}
                    </tr>
                  ) : (
                    <>Header Error</>
                  )}
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data.map((item: any, index: number) => (
                    <tr key={index}>
                      {header && header.length > 0 ? (
                        header.map((header, index) => (
                          <td key={index}>
                            {header.key === 'actions' ? (
                              <div>
                                <a
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  onClick={() => editCallback(item['id'], this.fetchData)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='svg-icon-3'
                                  />
                                </a>
                                <a
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                  onClick={() => deleteCallback(item['id'], this.fetchData)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                  />
                                </a>
                              </div>
                            ) : header.type == 'object' && header.subKey ? (
                              item[header.key][header.subKey]
                            ) : (
                              item[header.key]
                            )}
                          </td>
                        ))
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          ) : (
            // end::Table container
            <></>
          )}
          {this.renderPagination()}
        </div>
        {/* end::Body */}
      </div>
    )
  }
}

export default ResultViewer
