// Scheduler.tsx
import React, { useState } from 'react'
import './scheduler.css'
import SchedulerToolbar from './SchedulerToolbar'
import SchedulerEventEditModal from './SchedulerEventEditModal'

const hours = Array.from({ length: 24 }, (_, i) => `${i}:00`)
const resources = ['Resource A', 'Resource B', 'Resource C']

interface Event {
  id: number
  title: string
  start: number
  end: number
  resource: string
}

const initialEvents: Event[] = [
  { id: 1, title: 'Test', start: 9, end: 10, resource: 'Resource A' },
  { id: 2, title: 'Test-2', start: 12, end: 13, resource: 'Resource B' },
  { id: 3, title: 'Test-3', start: 15, end: 16, resource: 'Resource C' },
]

const Scheduler: React.FC = () => {
  const [events, setEvents] = useState<Event[]>(initialEvents)
  const [showModal, setShowModal] = useState(false)
  const [title, setTitle] = useState('')
  const [startHour, setStartHour] = useState<number | undefined>(undefined)
  const [endHour, setEndHour] = useState<number | undefined>(undefined)
  const [resource, setResource] = useState<string | undefined>(undefined)
  const [dragging, setDragging] = useState<{ resource: string; start: number; end: number } | null>(
    null
  )

  const handleShow = () => setShowModal(true)
  const handleClose = () => {
    setTitle('')
    setStartHour(undefined)
    setEndHour(undefined)
    setResource(undefined)
    setDragging(null)
    setShowModal(false)
  }

  const handleSave = () => {
    if (title && startHour !== undefined && endHour !== undefined && resource) {
      const id = new Date().getTime()
      const newEvent = { id, title, start: startHour, end: endHour, resource }
      setEvents([...events, newEvent])
      handleClose()
    }
  }

  const handleDelete = (id: number) => {
    console.log('Delete event with id:', id)
  }

  const handleCellMouseDown = (resourceName: string, hour: number) => {
    setResource(resourceName)
    setDragging({ resource: resourceName, start: hour, end: hour + 1 })
  }

  const handleCellMouseEnter = (hour: number) => {
    if (dragging) {
      setDragging((prev) => ({ ...prev!, end: hour + 1 }))
    }
  }

  const handleCellMouseUp = () => {
    if (dragging) {
      setEndHour(dragging.end)
      setStartHour(dragging.start)
      setResource(dragging.resource)
      handleShow()
      setDragging(null)
    }
  }

  return (
    <div className='container-fluid p-4'>
      <SchedulerToolbar handleShow={handleShow} />

      <div className='scheduler-container-custom'>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Resources</th>
              {hours.map((hour, index) => (
                <th key={index} className='text-center'>
                  {hour}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {resources.map((resourceName, rowIndex) => (
              <tr key={rowIndex}>
                <td className='text-center scheduler-resource-name'>{resourceName}</td>
                {hours.map((_, columnIndex) => {
                  const isDragging =
                    dragging &&
                    dragging.resource === resourceName &&
                    columnIndex >= dragging.start &&
                    columnIndex < dragging.end
                  const isOccupied = events.some(
                    (event) =>
                      event.resource === resourceName &&
                      event.start <= columnIndex &&
                      event.end > columnIndex
                  )

                  return (
                    <td
                      key={columnIndex}
                      className={`position-relative scheduler-cell ${
                        isDragging ? 'range-selecting' : isOccupied ? 'scheduler-cell' : ''
                      }`}
                      onMouseDown={() => handleCellMouseDown(resourceName, columnIndex)}
                      onMouseEnter={() => handleCellMouseEnter(columnIndex)}
                      onMouseUp={handleCellMouseUp}
                    >
                      {events
                        .filter(
                          (event) =>
                            event.resource === resourceName &&
                            event.start <= columnIndex &&
                            event.end > columnIndex
                        )
                        .map((event) => (
                          <div
                            key={event.id}
                            className='scheduler-event'
                            style={{
                              gridColumn: `${event.start + 1} / span ${event.end - event.start}`,
                              height: '100%',
                            }}
                          >
                            {event.title}
                          </div>
                        ))}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <SchedulerEventEditModal
        showModal={showModal}
        handleClose={handleClose}
        title={title}
        startHour={startHour}
        endHour={endHour}
        resource={resource}
        setTitle={setTitle}
        setStartHour={setStartHour}
        setEndHour={setEndHour}
        setResource={setResource}
        handleSave={handleSave}
        handleDelete={() => {}}
        hours={hours}
        resources={resources}
      />
    </div>
  )
}

export default Scheduler
