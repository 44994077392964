// Toolbar.tsx
import React from 'react'
import { Button } from 'react-bootstrap'

interface ToolbarProps {
  handleShow: () => void
}

const SchedulerToolbar: React.FC<ToolbarProps> = ({ handleShow }) => (
  <div className='mb-3'>
    <Button variant='primary' onClick={handleShow}>
      Add Event
    </Button>
  </div>
)

export default SchedulerToolbar
