import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { useEffect, useState } from 'react'
import { meApi } from '../../../../../app/modules/auth/core/_requests'
import { User } from '../../../../../app/modules/auth/core/_models'

export function MenuInner() {
  const intl = useIntl()
  const [user, setUser] = useState<User>()
  const [token, setToken] = useState<string | undefined>(() => {
    return localStorage.getItem('@auth/token') ?? undefined
  })

  useEffect(() => {
    const fetchUser = async () => {
      const user = await meApi(token) // meApi'yi çağırarak kullanıcı bilgilerini alıyoruz

      if (!user) {
        setToken(undefined)
        return
      }
      setUser(user.data)
    }
    fetchUser()
  }, [])
  let hasValidRole = false
  user?.roles.map((role) => {
    if (role == '1' || role == '2') {
      hasValidRole = true
    } else {
      hasValidRole = false
    }
  })

  if (!hasValidRole) {
    return (
      <>
        <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      </>
    )
  }
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      <MenuItem to='users/list' title='Users' />
    </>
  )
}
