/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 bg-white'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid bg-[#e7e7e7]'>
          <div className='w-lg-500px p-10 login-card-bg'>
            <Outlet />
          </div>
        </div>
        {/*
        <div className='d-flex flex-center flex-wrap px-5'>
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>
            <a href='#' className='px-5' target='_blank'>
              Plans
            </a>
            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
        </div>
        */}
      </div>

      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{ backgroundImage: `url(${toAbsoluteUrl('media/auth/login-bg-1.jpg')})` }}
      >
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          <Link to='/' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('media/logos/logo-white.svg')} className='h-250px' />
          </Link>
          {/*
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            Fast, Efficient and Productive
          </h1>
          
          <div className='text-white fs-base text-center'>
            In this kind of post,{' '}
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the blogger
            </a>
            introduces a person they’ve interviewed <br /> and provides some background information
            about
            <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              the interviewee
            </a>
            and their <br /> work following this is a transcript of the interview.
          </div>
          */}
        </div>
      </div>
    </div>
  )
}

export { AuthLayout }
